import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { ErrorMessage, Input } from '../../styles/form-styles'
import { navigate } from 'gatsby'
import { getApi, ChooseFormTitle, ChoosePlanDetailFooter } from './utils'

import {
  FormStyle,
  CheckboxInput,
  CheckboxLabel,
  PlanDetailsStyle,
} from './FormStyle'
import LoadingOverlay from 'react-loading-overlay-ts'

type AccountInput = {
  firstName: string
  lastName: string
  accountNum: number
  confirmAccountNum: number
  terms: boolean
}

const url = typeof window !== 'undefined' ? window.location.href : ''
const formType = url.slice(-2).substr(-2, 1)

const FormWithAccount = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm()
  const accountNum = useRef({})
  accountNum.current = watch('accountNum', '')
  const [loading, setLoading] = useState(false)

  const submitForm = async (data: AccountInput) => {
    setLoading(true)
    axios({
      url: getApi(),
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      data: JSON.stringify(data),
    })
      .then(response => {
        console.log(response)
        setLoading(false)
        reset()
        navigate('/protection/success')
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        navigate('/protection/failed')
      })
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Submitting your information..."
    >
      <PlanDetailsStyle>
        <FormStyle onSubmit={handleSubmit(submitForm)}>
          <div>
            <h3>Sign Up for Coverage</h3>
            {ChooseFormTitle(formType)}
            <p className="all-fields-req">All fields required</p>
          </div>
          <div className="field ">
            <label htmlFor="firstName">First Name</label>
            <Input
              type="text"
              id="firstName"
              placeholder="First Name"
              {...register('firstName', {
                required: true,
              })}
            />
            {errors.firstName && (
              <ErrorMessage>Please enter your first name</ErrorMessage>
            )}
          </div>

          <div className="field ">
            <label htmlFor="lastName">Last Name</label>
            <Input
              type="text"
              id="lastName"
              placeholder="Last Name"
              {...register('lastName', {
                required: true,
              })}
            />
            {errors.lastName && (
              <ErrorMessage>Please enter your last name</ErrorMessage>
            )}
          </div>

          <div className="field ">
            <label htmlFor="accountNum">Account Number</label>
            <Input
              type="number"
              id="accountNum"
              placeholder="555555"
              {...register('accountNum', {
                required: true,
                maxLength: 7,
                minLength: 5,
              })}
            />
            {errors.accountNum && (
              <ErrorMessage>Please enter a valid account number</ErrorMessage>
            )}
          </div>

          <div className="field ">
            <label htmlFor="confirmAccountNum">Confirm Account Number</label>
            <Input
              type="number"
              id="confirmAccountNum"
              placeholder="555555"
              {...register('confirmAccountNum', {
                required: true,
                validate: value =>
                  value === accountNum.current ||
                  'Account number does not match',
              })}
            />
            {errors.confirmAccountNum && (
              <ErrorMessage>Account number does not match</ErrorMessage>
            )}
          </div>
          <div className="field last-field">
            <div className="checkbox-field">
              <CheckboxInput
                type="checkbox"
                id="terms"
                {...register('terms', {
                  required: true,
                })}
              />
              <CheckboxLabel htmlFor="terms">
                <div className="content-footer">
                  {ChoosePlanDetailFooter(formType)}
                </div>
              </CheckboxLabel>
            </div>
            {errors.terms && (
              <ErrorMessage>
                You must agree to the Terms & Conditions in order to sign up for
                the Lively Protection Plan
              </ErrorMessage>
            )}
          </div>

          <button type="submit">Sign Up</button>
        </FormStyle>
      </PlanDetailsStyle>
    </LoadingOverlay>
  )
}

export default FormWithAccount
